import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Image from '../../components/Image';
import LogoWhite from '../../components/LogoWhite';
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';
// mock
import { seoPageData } from '../../_mock/seoPageData';
// asses
import loginImage from '../../assets/loginDoor2.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#2e4f7e',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
  justifyContent: 'center',
}));

const HeaderStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  margin: theme.spacing(4, 0, 4, 0),
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: theme.spacing(0, 1),
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 480,
    margin: theme.spacing(0, 2),
  },
}));

const ContainerStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'white',
  borderRadius: '20px',
  [theme.breakpoints.down('md')]: {
    // Full width for mobile view
    width: '100vw',
    padding: theme.spacing(3, 1, 1, 1),
    borderRadius: '10px',
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title={seoPageData.login.title} description={seoPageData.login.description}>
      <RootStyle>
        <HeaderStyle>
          <LogoWhite sx={{ width: { xs: '150px' } }} />
        </HeaderStyle>
        <ContainerStyle>
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h3" gutterBottom sx={{ p: 0, m: 0 }}>
                  Login
                </Typography>
                <Typography variant="subtitle2">
                  Don’t have an account? {''}
                  <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                    Get started
                  </Link>
                </Typography>{' '}
              </Box>
            </Stack>
            <LoginForm />
          </ContentStyle>
          {mdUp && (
            <SectionStyle>
              <Image visibleByDefault disabledEffect src={loginImage} alt="login" sx={{ px: 2, width: '520px' }} />
            </SectionStyle>
          )}
        </ContainerStyle>
      </RootStyle>
    </Page>
  );
}
